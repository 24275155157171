'use client';

import { type UseQueryOptions, useQuery } from '@tanstack/react-query';

import { validateZipCode } from '../../validateZipCode';

type TUseQueryArgs = {
  params?: {
    zipCode?: string;
  };
  options?: Omit<UseQueryOptions<boolean, Error>, 'queryKey' | 'queryFn'> & {
    onSuccess?: (data: boolean) => void;
  };
};

export function useValidateZipCode({ params = {}, options = {} }: TUseQueryArgs) {
  return useQuery<boolean, Error>({
    queryKey: ['validateZipCode', params.zipCode],
    queryFn: async ({ signal }) => {
      if (!params.zipCode || params.zipCode.length < 3) return false;

      const response = await validateZipCode({
        params,
        options: {
          signal,
        },
      });
      if (options.onSuccess) options.onSuccess(!!response);

      return !!response;
    },
    staleTime: 0,
    refetchOnWindowFocus: false,
    ...options,
  });
}
