import type { TApiModel, TGuestToken } from '@/common';
import { pwaApi } from '../api';

export async function getGuestToken(): Promise<TGuestToken | null> {
  try {
    const response = await pwaApi.get<TApiModel<TGuestToken>>('/guest-token');

    return response.data;
  } catch (error) {
    console.error('Error fetching guest token:', error);
    return null;
  }
}
