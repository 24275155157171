import type { TApiModel, TRequestOptions } from '@/common';

import { pwaApi } from '../api';

type TSearchProductsArgs = {
  params?: {
    zipCode?: string;
  };
  options?: TRequestOptions & RequestInit;
};
export async function validateZipCode({
  params = {},
  options = {},
}: TSearchProductsArgs): Promise<boolean | undefined> {
  try {
    const response = await pwaApi.get<TApiModel<{ zipcode_verified: boolean }>>(
      '/availability/validate-zipcode',
      {
        params,
        ...options,
      }
    );

    if (response.data) return response.data.zipcode_verified;

    return false;
  } catch (error) {
    console.error('Error validating zip code:', error);
    return false;
  }
}
