import { getPlatform } from './getPlatform';

// types.ts
interface Platform {
  os: string;
  browser: string;
  version: string;
}

interface SystemInfo {
  os: string;
  browser: string;
  browserVersion: string;
  screenWidth: number;
  screenHeight: number;
  colorDepth: number;
  pixelRatio: number;
  deviceMemory: number;
  hardwareConcurrency: number;
  maxTouchPoints: number;
  language: string;
  languages: readonly string[];
  timezone: string;
  effectiveType: string;
  downlink: number;
  rtt: number;
  saveData: boolean;
  navigationStart: number;
  performanceNow: number;
  timestamp: number;
  randomSeed: number;
}

interface UserAgentBrand {
  brand: string;
  version: string;
}

interface UserAgentData {
  brands?: UserAgentBrand[];
  platform?: string;
}

interface NavigatorExtended extends Navigator {
  deviceMemory?: number;
  connection?: {
    effectiveType?: string;
    downlink?: number;
    rtt?: number;
    saveData?: boolean;
  };
  userAgentData?: UserAgentData;
}

// uuid.ts
// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
class CryptoHelper {
  static isAvailable(): boolean {
    return typeof crypto !== 'undefined';
  }

  static getRandomValues(array: Uint32Array | Uint8Array): Uint32Array | Uint8Array | null {
    if (CryptoHelper.isAvailable()) {
      return crypto.getRandomValues(array);
    }
    return null;
  }

  static getNativeUUID(): string | null {
    return CryptoHelper.isAvailable() && crypto.randomUUID ? crypto.randomUUID() : null;
  }

  static getSecureRandom(): number {
    if (CryptoHelper.isAvailable()) {
      const array = new Uint32Array(1);
      CryptoHelper.getRandomValues(array);
      return array[0] / (2 ** 32 - 1);
    }
    return Date.now() / 1000000;
  }
}

class SystemInfoCollector {
  private readonly navigator: NavigatorExtended | null;
  private readonly platform: Platform;

  constructor() {
    this.navigator = typeof window !== 'undefined' ? window.navigator : null;
    this.platform = getPlatform();
  }

  getBasicInfo(): Pick<SystemInfo, 'os' | 'browser' | 'browserVersion'> {
    return {
      os: this.platform.os,
      browser: this.platform.browser,
      browserVersion: this.platform.version,
    };
  }

  getScreenInfo(): Pick<SystemInfo, 'screenWidth' | 'screenHeight' | 'colorDepth' | 'pixelRatio'> {
    return {
      screenWidth: window?.screen?.width ?? 0,
      screenHeight: window?.screen?.height ?? 0,
      colorDepth: window?.screen?.colorDepth ?? 0,
      pixelRatio: window?.devicePixelRatio ?? 1,
    };
  }

  getHardwareInfo(): Pick<SystemInfo, 'deviceMemory' | 'hardwareConcurrency' | 'maxTouchPoints'> {
    return {
      deviceMemory: this.navigator?.deviceMemory ?? 0,
      hardwareConcurrency: this.navigator?.hardwareConcurrency ?? 0,
      maxTouchPoints: this.navigator?.maxTouchPoints ?? 0,
    };
  }

  getLocalizationInfo(): Pick<SystemInfo, 'language' | 'languages' | 'timezone'> {
    return {
      language: this.navigator?.language ?? '',
      languages: this.navigator?.languages ?? [],
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  }

  getConnectionInfo(): Pick<SystemInfo, 'effectiveType' | 'downlink' | 'rtt' | 'saveData'> {
    const connection = this.navigator?.connection;
    return {
      effectiveType: connection?.effectiveType ?? '',
      downlink: connection?.downlink ?? 0,
      rtt: connection?.rtt ?? 0,
      saveData: connection?.saveData ?? false,
    };
  }

  getPerformanceInfo(): Pick<SystemInfo, 'navigationStart' | 'performanceNow'> {
    const getNavigationTiming = (): number => {
      if (window?.performance?.getEntriesByType('')) {
        const navigationEntry = performance.getEntriesByType('navigation')[0];
        return navigationEntry ? navigationEntry.startTime : 0;
      }
      return 0;
    };

    return {
      navigationStart: getNavigationTiming(),
      performanceNow: window?.performance?.now() ?? 0,
    };
  }

  getAllInfo(): SystemInfo {
    return {
      ...this.getBasicInfo(),
      ...this.getScreenInfo(),
      ...this.getHardwareInfo(),
      ...this.getLocalizationInfo(),
      ...this.getConnectionInfo(),
      ...this.getPerformanceInfo(),
      timestamp: Date.now(),
      randomSeed: CryptoHelper.getSecureRandom(),
    };
  }
}

// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
class HashGenerator {
  static generate(data: SystemInfo): string {
    const combinedInfo = JSON.stringify(data);
    let hashValue = 0n;
    const strLen = combinedInfo.length;
    const prime1 = 31n;
    const prime2 = 37n;

    for (let i = 0; i < strLen; i++) {
      const char = BigInt(combinedInfo.charCodeAt(i));
      hashValue = ((hashValue + char) * prime1) % 2n ** 64n;
      hashValue = (hashValue * prime2) % 2n ** 64n;
    }

    if (hashValue < BigInt(Number.MAX_SAFE_INTEGER)) {
      return Number(hashValue).toString(36);
    }

    const convertBigIntToBase36 = (bigInt: bigint): string => {
      const decimalString = bigInt.toString();
      return Number.parseInt(decimalString).toString(36);
    };

    const chunk1 = (hashValue >> 32n) & 0xffffffffn;
    const chunk2 = hashValue & 0xffffffffn;

    return `${convertBigIntToBase36(chunk1)}-${convertBigIntToBase36(chunk2)}`;
  }

  static getSecureRandomPart(): string {
    if (CryptoHelper.isAvailable()) {
      const array = new Uint32Array(1);
      CryptoHelper.getRandomValues(array);
      return BigInt(array[0]).toString(36).padStart(6, '0');
    }
    return BigInt(Date.now()).toString(36).padStart(6, '0');
  }
}

// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
class UUIDGenerator {
  static generateCustomUUID(): string {
    try {
      const systemInfo = new SystemInfoCollector();
      const allInfo = systemInfo.getAllInfo();
      const finalHash = HashGenerator.generate(allInfo);
      const timestamp = BigInt(Date.now()).toString(36);
      const randomPart = HashGenerator.getSecureRandomPart();
      const prefix = `${allInfo.os}-${allInfo.browser}`.toLowerCase();

      return `${prefix}-${timestamp}-${finalHash}-${randomPart}`;
    } catch (error) {
      console.error('Error generating UUID:', error);
      return UUIDGenerator.generateFallbackUUID();
    }
  }

  static generateFallbackUUID(): string {
    const getFallbackSecureId = (): string => {
      if (CryptoHelper.isAvailable()) {
        const array = new Uint32Array(2);
        CryptoHelper.getRandomValues(array);
        return Array.from(array)
          .map((n) => n.toString(36))
          .join('')
          .substring(2, 11);
      }
      return Date.now().toString(36);
    };

    const platform = getPlatform();
    const prefix = `${platform.os}-${platform.browser}`.toLowerCase();
    const timestamp = Date.now().toString(36);
    const random = getFallbackSecureId();

    return `${prefix}-fallback-${timestamp}-${random}`;
  }

  static generateCryptoUUID(): string {
    const nativeUUID = CryptoHelper.getNativeUUID();
    if (nativeUUID) return nativeUUID;

    if (CryptoHelper.isAvailable()) {
      const buffer = new Uint8Array(16);
      CryptoHelper.getRandomValues(buffer);

      buffer[6] = (buffer[6] & 0x0f) | 0x40;
      buffer[8] = (buffer[8] & 0x3f) | 0x80;

      const segments = [
        buffer.slice(0, 4),
        buffer.slice(4, 6),
        buffer.slice(6, 8),
        buffer.slice(8, 10),
        buffer.slice(10, 16),
      ].map((segment) =>
        Array.from(segment)
          .map((b) => b.toString(16).padStart(2, '0'))
          .join('')
      );

      return segments.join('-');
    }

    return UUIDGenerator.generateCustomUUID();
  }
}

export function generateUUID(): string {
  return UUIDGenerator.generateCryptoUUID();
}
