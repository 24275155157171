'use client';

import { cn } from '@/lib/utils';
import { useFormik } from 'formik';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import * as Yup from 'yup';

import { useValidateZipCode } from '@/services/pwa/availability/hooks/queries/useValidateZipCode';
import { getLink } from '@/utils/getLink';

import { usePathname } from 'next/navigation';
import { Button, Icon, IconButton, Input } from '../atoms';
import { Select } from '../atoms/Select';

const validationSchema = Yup.object().shape({
  zipCode: Yup.string()
    .min(4, 'Please enter a 5 digit zip code')
    .required('Please enter a valid 5 digit zip code'),
  occasionId: Yup.string().min(1, 'Occasion is required').required('Occasion is required'),
});

type TOccasion = {
  id: string;
  key: string;
  label: string;
};
type TQuickGiftFinderProps = {
  className?: string;
};
export function QuickGiftFinder({ className }: Readonly<TQuickGiftFinderProps>) {
  const t = useTranslations();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isZipValid, setIsZipValid] = useState(false);

  const occasionsData = t.raw('occasionsData') as TOccasion[];

  const formik = useFormik({
    initialValues: {
      zipCode: '',
      occasionId: '',
    },
    validationSchema,
    onSubmit: async (data) => {
      if (!isZipValid) return;

      const occasion = occasionsData.find((o) => o.id === data.occasionId);
      if (!occasion) return;
      const url = getLink('quickGiftFinder', {
        occasionId: occasion.id,
        occasionKey: occasion.key,
        zipCode: data.zipCode,
      });
      window.open(url, '_self');
    },
  });

  const { isLoading: isLoadingValidateZipCode } = useValidateZipCode?.({
    params: { zipCode: formik.values.zipCode },
    options: {
      onSuccess: (isVerified) => {
        setIsZipValid(!!isVerified);
      },
      enabled: formik.values.zipCode.length > 3,
    },
  }) ?? { isLoading: false };

  const isZipCodeError =
    (formik.touched.zipCode && formik.errors.zipCode) ||
    (formik.values.zipCode?.length > 4 && !isZipValid);

  return (
    <div className={cn('w-full border-navy-400 border-t border-solid bg-navy-500', className)}>
      <div
        className={cn(
          'container flex flex-col items-center justify-center gap-4 py-2 text-bg-body sm:gap-6 sm:py-5 lg:flex-row',
          isExpanded && 'pb-4'
        )}
      >
        <div className="relative flex w-full items-center justify-center lg:w-fit">
          <div className="flex items-center gap-2">
            <Icon name="gift-box" className="h-8 w-8 sm:h-10 sm:w-10" />
            <h3 className="subtitle1-bold">{t('quickGiftFinder')}</h3>
          </div>
          <IconButton
            variant="icon"
            icon={isExpanded ? 'down-chevron' : 'up-chevron'}
            color="white"
            onClick={() => setIsExpanded((prev) => !prev)}
            className="absolute right-0 lg:hidden"
            aria-expanded={isExpanded}
            aria-label={t('toggleQuickGiftFinder')}
          />
        </div>

        <form
          className={cn(
            'flex w-full flex-col items-center gap-4 lg:w-auto lg:flex-row',
            isExpanded ? 'flex' : 'hidden lg:flex'
          )}
          onSubmit={formik.handleSubmit}
        >
          <Input
            label={t('recipientZipCode')}
            className="w-full lg:w-[210px]"
            wrapperClassName="[&_p]:absolute [&_p]:text-fg-inverse-default [&_p]:mt-[1px]"
            name="zipCode"
            value={formik.values.zipCode}
            onChange={(e) => {
              const value = e.target.value;
              const numberOnly = value.replace(/\D/g, '');
              formik.setFieldValue('zipCode', numberOnly);
            }}
            status={isZipCodeError ? 'error' : 'initial'}
            maxLength={5}
            isLoading={isLoadingValidateZipCode}
            {...(isZipCodeError ? { helperText: t('invalidZipCode') } : {})}
          />

          <div
            style={{ position: 'relative' }}
            className="[&_select]:!h-full [&_select]:!w-full w-full lg:min-w-[272px] [&_select]:inset-0"
          >
            <Select
              name="occasionId"
              options={occasionsData}
              value={formik.values.occasionId}
              onValueChange={(value) => formik.setFieldValue('occasionId', value)}
              placeholder={t('selectAnOccasion')}
              status={formik.touched.occasionId && formik.errors.occasionId ? 'error' : 'initial'}
              helperText={
                formik.touched.occasionId && formik.errors.occasionId
                  ? formik.errors.occasionId
                  : ''
              }
            />
          </div>
          <Button
            variant="outlined"
            color="white"
            className="w-full"
            disabled={
              isLoadingValidateZipCode ||
              (formik.values.zipCode.length > 4 && !isZipValid) ||
              formik.isSubmitting
            }
          >
            {t('findAGiftNow')}
          </Button>
        </form>
      </div>
    </div>
  );
}

const pathsWithQuickGiftFinder = ['/'];
export function ConditionalQuickGiftFinder() {
  const pathname = usePathname();
  if (!pathsWithQuickGiftFinder.includes(pathname)) {
    return null;
  }

  return <QuickGiftFinder className="sticky right-0 bottom-0 left-0 z-50" />;
}
